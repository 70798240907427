import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import appSliceReducer from '../features/app/state/appSlice';
import { artworksApi } from '../features/artworks/api/artworksApi'

export const store = configureStore({
  reducer: {
    [artworksApi.reducerPath]: artworksApi.reducer,
    app: appSliceReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(artworksApi.middleware),
});

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
