import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export function getErrorText(error: FetchBaseQueryError | SerializedError | undefined): string {
    if (!error)
        return `Unknown error occured`;

    if (`message` in error)
        return error.message ?? error.name ?? `Unknown error occured`;

    if (`data` in error && error.data) {
        if (typeof error.data === `string`)
            return error.data;

        if (typeof error.data === 'object') {
            if (`message` in error.data)
                return (error.data as { message: string; }).message;

            if (`statusText` in error.data)
                return (error.data as { statusText: string; }).statusText;
        }
    }

    return `Unknown error occured`;
}
