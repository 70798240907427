import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';

import SwipeableImage from './swipeableImage';

import { useGetArtworksByCategoryQuery } from '../api/artworksApi'
import { useCategoryParam } from "../hooks/useCategoryParam";

import getCategoryLowercaseName from '../utils/getCategoryLowercaseName';
import { getErrorText } from '../utils/getErrorText';

export default function CategoryItemPage() {
    const navigate = useNavigate();
    const category = useCategoryParam();
    const { index: indexParam } = useParams();

    const { data, error, isLoading, } = useGetArtworksByCategoryQuery(category);

    if (isLoading)
        return <CircularProgress />;

    if (error)
        throw new Error(`Error fetching data. ${getErrorText(error)}`)

    if (!data || data.length === 0)
        throw new Error(`No data found`);

    const index = Number(indexParam);

    if (!Number.isFinite(index) || index < 0 || !data[index])
        throw new Error(`Invalid index`);

    const artwork = data[index];

    const previousArtworkIndex = index > 0 ? index - 1 : data.length - 1;
    const nextArtworkIndex = index < data.length - 1 ? index + 1 : 0;

    preloadImage(`https://beatajakimiak.art.pl/${data[previousArtworkIndex].fileLocation}`);
    preloadImage(`https://beatajakimiak.art.pl/${data[nextArtworkIndex].fileLocation}`);

    return (
        <>
            <SwipeableImage
                src={`https://beatajakimiak.art.pl/${artwork.fileLocation}`}
                alt={artwork.title}
                title={artwork.title}
                swipedLeft={() => navigate(`/category/${getCategoryLowercaseName(category)}/${previousArtworkIndex}`)}
                swipedRight={() => navigate(`/category/${getCategoryLowercaseName(category)}/${nextArtworkIndex}`)}
            />
        </>
    );
}

async function preloadImage(src: string) {
    const cache = await caches.open('images');

    const isImageCached = await cache.match(src);

    if (isImageCached)
        return;

    const response = await fetch(src);

    await cache.put(src, response);
}