import { useParams } from 'react-router-dom';
import { Category } from '../api/types';
import mapCategoryFromString from '../utils/mapCategoryFromString';

export function useCategoryParam(): Category {
    const { category: categoryParam } = useParams();

    if (!categoryParam)
        throw new Error(`Category not present`);

    const category: Category | undefined = mapCategoryFromString(categoryParam);

    if (!category)
        throw new Error(`Invalid category`);

    return category;
}
