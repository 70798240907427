import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography"
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ContactPage() {
    const emailVariant = useMediaQuery('(max-width:360px)') ? `body2` : `body1`;

    return (
        <>
            <Typography variant="body1" component="p" sx={{ m: 2 }}>
                e-mail: <Typography variant={emailVariant} component={Link} href="mailto:beata.jakimiak.rychlicka@gmail.com">beata.jakimiak.rychlicka@gmail.com</Typography>
            </Typography>
            <Typography variant="body1" component="p" sx={{ m: 2 }}>
                Read about me: <Link href="https://www.graffus.com/artykuly/w-graffusie/portretuje-emocje">Graffus</Link>, <Link href="https://artpubalbum.blogspot.ie/2012/12/beata-jakimiak-rychlicka-rysunek.html">Artpub</Link>
            </Typography>
            <Typography variant="body1" component="p" sx={{ m: 2 }}>
                Buy my art: <Link href="https://www.touchofart.eu/en/gallery/Beata-Jakimiak">Touch of Art</Link>
            </Typography>
            <Typography variant="body1" component="p" sx={{ m: 2 }}>© Copyright</Typography>
            <Typography variant="body1" component="p" sx={{ m: 2 }}>It is strictly forbidden to copy or use the works posted on this website. In particular, it is forbidden to publish all or part of the material, remove the author's signature, or use the works for commercial and non-commercial purposes without the knowledge and consent of the author.</Typography>
        </>
    );
}