import React from 'react';
import { Outlet } from 'react-router';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import { PaletteMode } from '@mui/material';

import Header from '../common/header/header';
import { useSelector } from 'react-redux';
import { selectThemeMode } from './state/appSlice';

function App() {
    const themeMode = useSelector(selectThemeMode);

    const theme = React.useMemo(() => createTheme(getDesignTokens(themeMode)), [themeMode]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Header />
            <Outlet />
        </ThemeProvider>
    );
}

const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                primary: {
                    main: grey[900],
                },
            }
            : {
            }),
    },
});


export default App;
