import React from 'react'

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import MenuIcon from '@mui/icons-material/Menu';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContactIcon from '@mui/icons-material/Email';
import ArtworksIcon from '@mui/icons-material/Palette';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@mui/material/Link';
import { Category } from '../../artworks/api/types';
import getCategoryLowercaseName from '../../artworks/utils/getCategoryLowercaseName';

import logoImg from './logo.png'
import { useAppDispatch } from '../../../app/hooks';
import { selectThemeMode, setThemeMode } from '../../app/state/appSlice';

export default function Header() {
    const dispatch = useAppDispatch();

    const themeMode = useSelector(selectThemeMode);

    const [isCategoriesMenuOpen, setIsCategoriesMenuOpen] = React.useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const toggleCategoriesMenu = () => {
        setIsCategoriesMenuOpen(!isCategoriesMenuOpen);
    };

    return (
        <AppBar position="static" sx={{ mb: 3, height: `56px` }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ justifyContent: `space-between` }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open menu"
                        onClick={toggleDrawer}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Link component={RouterLink} to={`/`}>
                        <Box component="img" src={logoImg} alt={`Logo`} sx={{ height: 45, display: 'block' }} />
                    </Link>
                    <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer}>
                        <List
                            sx={{ width: '100%', minWidth: 200, maxWidth: 280, bgcolor: 'background.paper' }}
                            component="nav"
                        >
                            <ListItemButton onClick={toggleCategoriesMenu}>
                                <ListItemIcon>
                                    <ArtworksIcon />
                                </ListItemIcon>
                                <ListItemText primary="Artworks" />
                                {isCategoriesMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </ListItemButton>
                            <Collapse in={isCategoriesMenuOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }} component={RouterLink} to={`/category/${getCategoryLowercaseName(Category.Drawing)}`} onClick={toggleDrawer}>
                                        <ListItemText primary="Drawing" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ pl: 4 }} component={RouterLink} to={`/category/${getCategoryLowercaseName(Category.Painting)}`} onClick={toggleDrawer}>
                                        <ListItemText primary="Painting" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ pl: 4 }} component={RouterLink} to={`/category/${getCategoryLowercaseName(Category.GraphicArt)}`} onClick={toggleDrawer}>
                                        <ListItemText primary="Graphic" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ pl: 4 }} component={RouterLink} to={`/category/${getCategoryLowercaseName(Category.GraphicDesign)}`} onClick={toggleDrawer}>
                                        <ListItemText primary="Graphic Design" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ pl: 4 }} component={RouterLink} to={`/category/${getCategoryLowercaseName(Category.Backstage)}`} onClick={toggleDrawer}>
                                        <ListItemText primary="Backstage" />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                            <ListItemButton component={RouterLink} to={`/contact`} onClick={toggleDrawer}>
                                <ListItemIcon>
                                    <ContactIcon />
                                </ListItemIcon>
                                <ListItemText primary="Contact" />
                            </ListItemButton>
                        </List>
                        <Divider />
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: 'background.default',
                                color: 'text.primary',
                                borderRadius: 1,
                                p: 3,
                            }}
                        >
                            {themeMode} mode
                            <IconButton sx={{ ml: 1 }}
                                onClick={() => dispatch(setThemeMode(themeMode === `light` ? `dark` : `light`))}
                                color="inherit"
                            >
                                {themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                            </IconButton>
                        </Box>
                    </Drawer>
                </Toolbar>
            </Container>
        </AppBar>
    );
}