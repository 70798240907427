import * as React from 'react'
import { useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useGetArtworksByCategoryQuery } from '../api/artworksApi'
import getCategoryLowercaseName from '../utils/getCategoryLowercaseName';
import CircularProgress from '@mui/material/CircularProgress';
import { useCategoryParam } from "../hooks/useCategoryParam";
import { getErrorText } from '../utils/getErrorText';

export default function CategoryPage() {
    const category = useCategoryParam();
    const navigate = useNavigate();
    const { data, error, isLoading } = useGetArtworksByCategoryQuery(category);

    const isXs = useMediaQuery('(max-width:400px)');
    const isSm = useMediaQuery('(max-width:700px)');
    const isLg = useMediaQuery('(max-width:1000px)');
    const isXl = useMediaQuery('(max-width:1300px)');

    const cols = isXs ? 2 : isSm ? 3 : isLg ? 4 : isXl ? 5 : 6;

    if (isLoading)
        return <CircularProgress />;

    if (error)
        throw new Error(`Error fetching data. ${getErrorText(error)}`)

    if (!data || data.length === 0)
        throw new Error(`No data found`);

    return (
        <Container>
            <ImageList sx={{ width: `100%` }} cols={cols}>
                {data.map((artwork, index) => (
                    <ImageListItem key={artwork.id} onClick={() => navigate(`/category/${getCategoryLowercaseName(category)}/${index}`)}>
                        <img
                            src={`https://beatajakimiak.art.pl/${artwork.miniatureLocation}`}
                            alt={artwork.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Container >
    );
}