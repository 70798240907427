
export type ArtworkRawResponse = {
    Id: number,
    Title: string,
    Category: Category,
    FileLocation: string,
    MiniatureLocation: string,
    Width: number,
    Height: number,
}

export type Artwork = {
    id: number,
    title: string,
    category: Category,
    fileLocation: string,
    miniatureLocation: string,
    width: number,
    height: number,
}

export enum Category {
    Drawing = 1,
    Painting = 2,
    GraphicArt = 3,
    GraphicDesign = 4,
    Backstage = 5,
}