import React from 'react'

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useSwipeable } from 'react-swipeable';

export default function SwipeableImage(props: {
    src: string,
    alt: string,
    title: string,
    swipedLeft: () => void,
    swipedRight: () => void,
}) {
    const swipeableHandlers = useSwipeable({
        onSwipedLeft: props.swipedLeft,
        onSwipedRight: props.swipedRight,
        trackMouse: true,
        swipeDuration: 750
    });

    return (
        <Stack sx={{ px: 2 }} spacing={1} justifyContent="center" >
            <Box
                component="img"
                src={props.src}
                alt={props.alt}
                sx={{ maxWidth: `100%`, maxHeight: `100%` }}
                {...swipeableHandlers}
            />
            <Typography sx={{ textAlign: `center` }}>{props.title}</Typography>
        </Stack>
    );
}