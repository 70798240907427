import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import isMobile from 'ismobilejs';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
import App from './features/app/app';
import ErrorPage from './features/common/error/errorPage'
import HomePage from './features/home/homePage';
import CategoryPage from './features/artworks/components/categoryPage';
import ContactPage from './features/contact/contactPage';
import CategoryItemPage from './features/artworks/components/categoryItemPage';
import { ArtworkRawResponse } from './features/artworks/api/types';

redirectToDesktopIfNotMobile();

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            { index: true, element: <HomePage /> },
            { path: 'contact', element: <ContactPage /> },
            { path: 'category/:category', element: <CategoryPage /> },
            { path: 'category/:category/:index', element: <CategoryItemPage /> },
        ],
    },
]);

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

async function redirectToDesktopIfNotMobile() {
    const isMobilePhone = isMobile(window.navigator.userAgent).phone

    if (isMobilePhone)
        return;

    window.location.href = await getDesktopRedirectHref();
}

async function getDesktopRedirectHref(): Promise<string> {
    const desktopRoot = `https://beatajakimiak.art.pl/#!`;

    const urlSegments = window.location.pathname.split('/').filter(segment => segment !== '');

    const isRootRequest = !urlSegments || urlSegments.length === 0;
    const isContactRequest = urlSegments && urlSegments[0] === `contact`;
    const isCategoryArtworksRequest = urlSegments?.length === 2 && urlSegments[0] === `category`;
    const isArtworkRequest = urlSegments?.length === 3 && urlSegments[0] === `category`;

    if (isRootRequest)
        return desktopRoot;

    if (isContactRequest)
        return `${desktopRoot}/contact`;

    if (isCategoryArtworksRequest)
        return `${desktopRoot}/artworks/` + urlSegments[1];

    if (isArtworkRequest) {
        const category = urlSegments[1];
        const artworkIndex = Number(urlSegments[2]);

        const response = await fetch(`https://beatajakimiak.art.pl/Home/GetArtworksByCategory/${category}`);

        if (!response.ok)
            return desktopRoot;

        const responseJson: ArtworkRawResponse[] = await response.json();

        const artworkId = responseJson[artworkIndex]?.Id;

        if (!artworkId)
            return desktopRoot;

        return `${desktopRoot}/artwork/${artworkId}`;
    }

    return desktopRoot;
}