/* eslint-disable no-param-reassign */

import { PaletteMode } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';
import { AppState } from './appTypes';

const localStorageThemeModeKeyName = `themeMode`;

const slice = createSlice({
    name: `app`,
    initialState: { themeMode: localStorage.getItem(localStorageThemeModeKeyName) ?? `light` } as AppState,
    reducers: {
        setThemeMode: (state, { payload }: PayloadAction<PaletteMode>) => {
            localStorage.setItem(localStorageThemeModeKeyName, payload);
            state.themeMode = payload;
        },
    },
});

export default slice.reducer;
export const { setThemeMode } = slice.actions;

export const selectThemeMode = (state: RootState) => state.app.themeMode;