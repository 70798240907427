import React from 'react'

import GlobalStyles from '@mui/material/GlobalStyles';

export default function Home() {
    return <GlobalStyles styles={{ 
        html: { 
            backgroundImage: `url(background.jpg)`,
            backgroundSize: `cover`,
            backgroundRepeat: `no-repeat`,
            backgroundPosition: `center`,
            backgroundPositionY: 55,
            minHeight: `100%`,
        } 
    }} />
}