import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { Artwork, ArtworkRawResponse, Category } from './types'

export const artworksApi = createApi({
  reducerPath: 'artworksApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://beatajakimiak.art.pl' }),
  endpoints: (builder) => ({
    getArtworksByCategory: builder.query<Artwork[], Category>({
      query: (category) => `Home/GetArtworksByCategory/${category}`,
      transformResponse: (response: ArtworkRawResponse[]) => response.map(r => {
        return {
          id: r.Id,
          title: r.Title,
          category: r.Category,
          fileLocation: r.FileLocation,
          miniatureLocation: r.MiniatureLocation,
          width: r.Width,
          height: r.Height,
        }
      }),
    }),
  }),
})

export const { useGetArtworksByCategoryQuery } = artworksApi